/* *************************************************************
 * Dependency / Vendor CSS
 */
require('normalize.css/normalize.css')
require('bootstrap/dist/css/bootstrap.min.css')

/* *************************************************************
 * Dependency / Vendor JS
 */
require('bootstrap')

/* *************************************************************
 * App CSS
 */
require('./styles/index.scss')

/* *************************************************************
 * App JS
 */
require('./js/retina')
require('./js/main')
